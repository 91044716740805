/* .App {
  
}  */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Source Sans Pro';
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

*,
*:before,
*:after {
  box-sizing: inherit;
} 

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  font-size: 16px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h2,
h3 {
  font-weight: normal;
}

p {
  margin: 0 0 1.4em 0;
} 

select {
  font-size: 16px;
  width: 40%;
  padding: 4px;
}

.text-center {
  text-align: center;
}

.title-case {
  text-transform: capitalize;
}

.header-bar {
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: white;
  border-radius: 15px;
}

.mid-div {
  position: sticky;
  display: flex;
  justify-content: space-between;
  background-image: url('./assets/sita-blue.jpeg');
  margin: 30px 0px 30px 0px;
}

.title-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  padding: 0px;
  vertical-align: middle;
  margin-bottom: 0.6em;
}

.title-center-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  margin-top: 1.9em;
}

.title-success-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  margin-top: 0.6em;
}

.title-explain-text {
  color: white;
  font-size: calc(10px + 0.390625vw);
  width: 100%;
  padding: 0px;
}

.bold-bar-text {
  color: white;
  font-weight: bold;
  font-size: calc(10px + 0.390625vw);
}

.bar-text {
  color: white;
  font-size: calc(7px + 0.390625vw);
  margin-left: 5px;
}

.content-text {
  color: black;
  font-size: calc(15px + 0.390625vw);
  width: 100%;
}

.plane-row {
  position: relative;
  display: table;
  min-height: 14em;
  background-image: url('./assets/airplane-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px 40px; 
}

.bar-row {
  background-image: url('./assets/sita-blue.jpeg');
  padding: 20px 40px; 
}

.content-row {
  background: white;
  padding: 20px 40px;
  overflow: hidden;
}

.header {
  height: 60px;
  padding: 15px 40px;
}

.footer {
  position: relative;
  color: white;
  font-size: calc(10px + 0.390625vw);
  background: #484848;
  padding: 20px 40px; 
  height: calc(60px + 0.390625vw);

}

.one-third-column {
  float: left;
  width: 33.33%;
}

.one-third-position {
  width: 33.33%;
  float: left;
  position: relative;
}

.two-third-column {
  float: left;
  width: 66.66%;
}

@media (max-width: 720px) {
  .one-third-column {
    float: none;
    width: 100%;
  }
  
  .two-third-column {
    float: none;
    width: 100%;
  }
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.passport-div {
  position: relative;
  margin: 0 auto;
  /* min-height: 256px; */
  height: 51.3vmin;
  max-height: 513px; 
  
  /* min-width: 360px; */
  width: 72vmin;
  max-width: 720px;
  
  font-size: 1.8vmin;
  text-transform: uppercase;
  background-image: url('./assets/utopia-passport.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.type {
  position: absolute;
  top: 18%;
  left: 35.75%;
}

.country-code {
  position: absolute;
  top: 18%;
  left: 48.25%;
}

.passport-number {
  position: absolute;
  top: 18%;
  left: 70%;
  overflow: hidden;
  max-width: 26.25%;
}

.surname {
  position: absolute;
  top: 27.5%;
  left: 35.75%;
}

.given-names {
  position: absolute;
  top: 34.5%;
  left: 35.75%;
}


.nationality {
  position: absolute;
  top: 42.5%;
  left: 35.75%;
}

.birth-date {
  position: absolute;
  top: 50%;
  left: 35.75%;
}

.sex {
  position: absolute;
  left: 70%;
  top: 57.5%;
}

.birth-place {
  position: absolute;
  top: 57.5%;
  left: 35.75%;
}

.issue-date {
  position: absolute;
  top: 65%;
  left: 35.75%;
}

.authority {
  position: absolute;
  top: 65%;
  left: 70%;
}

.expiry-date {
  position: absolute;
  top: 72.25%;
  left: 35.75%;
}

.chip-photo {
  position: absolute;
  top: 25%;
  left: 30px;
  max-width: 27%;
  object-fit: cover;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  /* position: relative;
  display: inline-block; */
  font-size: 16px;
  width: 40%;
  padding: 4px;
}

.spacing {
  margin-top: 20px;
}

.reset-btn {
  position: absolute;
  right: 0;
  padding: 10px 20px;
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 15px;
  color: white;
  border: none;
  background: #0095da;
}
.reset-btn:hover {
  cursor: pointer;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.loader {
  width: 30%;
  aspect-ratio: 1 / 1;
  border: 5px solid #0095da;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
